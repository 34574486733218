#users-page,
#reboot-page,
#restore-page,
#information-page,
#remote-access-page,
#syslog-page,
#datetime-page,
#information-page,
#tr069-config-page,
#change-to-firmware-version-page,
#leds-page {
    max-width: var(--max-width-box);
    width: var(--width-box-small);
    margin: 0 auto;
    text-align: left;
    padding-bottom: 40px;
}

#users-page .password-meter {
    height: 100px;
    margin-bottom: 10px;
}

#acl-admin-page {
    width: var(--width-box-medium);
    max-width: var(--max-width-box);
    margin: 0 auto;
    padding-bottom: 40px;
}

#reboot-page .info-text {
    margin: 20px 0 20px 0;
    margin-top: 30px;
    text-align: center;
}

#reboot-page form .daily-reboot-clock {
    display: flex;
    align-items: center;
    justify-content: center;
}

#reboot-page form .daily-reboot-clock span {
    font-weight: bold;
    color: var(--text-color);
    margin: 0 5px;
}

#card-reboot-status:not(.warning) {
    color: var(--itb-green);
}

#restore-page .info-text {
    margin: 20px 0 20px 0;
    margin-top: 30px;
    text-align: center;
}

#user-form .password-info {
    color: orange;
    margin: 10px;
    font-size: 0.79em;
    text-align: justify;
}

#information-page .info-text {
    border-radius: 10px;
    padding: 20px;
    max-height: 60vh;
    overflow-y: scroll;
}

#users-page .info-text {
    padding: 10px 0 10px 0;
    font-weight: bold;
    text-align: center;
    font-size: 18px;
}

.acl-admin-config-subtitle {
    padding-top: 50px;
}

.acl-admin-config-list-wrapper {
    overflow-x: auto;
}

.acl-admin-config-list-wrapper .list {
    width: 725px;
    margin-left: 0px;
}

#acl-admin-page strong {
    color: orange;
    font-size: 0.75em;
    display: block;
    margin: 0 auto;
    text-align: center;
}