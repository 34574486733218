#login-page {
    width: 100%;
    height: 100%;
}

#login-page .password-meter {
    height: 120px;
    text-align: center;
}

.login-form-container {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    top: -30px;
}

.login-logo {
    width: 200px;
}

.login-logo-brand-moto {
    position: absolute;
    top: 170px;
    width: 250px;
    text-align: right;
    color: white;
    font-weight: bold;
    font-family: "SF Pro", "Roboto", "Arial", "Helvetica", sans-serif;
}

#header-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 0 6px 6px;
    height: 300px;
    width: 100%;
    border-bottom: 1px solid #dfe2e6;
}

.first-login-form input:valid + label, .first-login-form input.itb-input:read-only + label, .first-login-form input:valid + .password-meter-tooltip label {
    top: -7px;
} 

#login-page .checkbox-group .after-input::before {
    top: 4px;
}

#login-page .checkbox-group .after-input::after {
    top: 8px;
}

#login-page label #terms-link {
    cursor: pointer;
    color: var(--itb-green);
}

#login-page .login-terms-of-use{
    height: 70vh;
    max-width: 520px;
    overflow-y: scroll;
    margin-bottom: 15px;
}

#login-page .login-terms-of-use .info-text{
    max-width: var(--max-width-box);
    width: var(--width-box-medium);
    overflow: auto;
    padding-bottom: 40px;
}


#login-page .login-terms-of-use div{
    margin: 0 auto;
    overflow: auto;
}